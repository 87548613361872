.header {
  position: relative;
  z-index: 10;
  display: block;
  margin: 0;
  padding: 0;
}

.b_logo {
  padding: 65px 0 240px 0;
}

.b_logo a {
  display: block;
  width: 290px;
  height: 100px;
  position: absolute;
}

.b_auth {
  padding: 112px 0 59px 203px;
  overflow: hidden;
}

.b_auth .row_avatar {
  float: left;
}

.row_auth {
  position: relative;
  float: left;
  width: 136px;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.2px;
  margin: 0 0 0 56px;
}

.row_auth:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  height: 100%;
  background: url('/images/auth-bg.jpg') no-repeat center;
  background-size: cover;
  z-index: -1;
}

.row_auth_open {
  text-align: left;
  height: 68px;
  margin: -6px 0 0 20px;
  padding: 17px 0;
}

.cabinet_btn {
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
}

.cabinet_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b_auth .row_auth .title {
  padding: 3px 0 6px 0;
}

.btn_brown_round,
.btn_yellow_round {
  cursor: pointer;
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding: 4px 18px 5px;
  border-radius: 12px;
  border: 1px solid #825d5d;
  background: var(--btn-brown-grad, linear-gradient(180deg, #f0b35d 0%, #ce9354 29%, #a25b47 56.5%, #9e5946 86%, #c88057 100%));
  z-index: 1;
  display: inline-block;
  box-shadow: 0px 2px 2px 1px rgba(92, 50, 0, 0.2);
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: -1;
    background: var(--btn-brown-grad, linear-gradient(180deg, #d09542 0%, #c68641 29%, #a25b47 56.5%, #8e4734 86%, #bd7144 100%));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.btn_yellow_round {
  margin-right: 20px;
  text-wrap: nowrap;
  font-weight: normal;
  color: #744d00;
  border: 1px solid #b59272;
  background: var(--btn-yellow-grad, linear-gradient(180deg, #fef5d5 0%, #f2d673 73%, #ffe896 97.5%));
  &:before {
    background-color: #f2d673;
    background: var(--btn-yellow-grad, linear-gradient(180deg, #fceebb 0%, #e9cc62 73%, #f7dd80 97.5%));
  }
}

// .b_form_login .error {
//   background-color: #ff8a8a;
// }

.b_auth .row_auth .logout {
  display: block;
  margin: 0 auto;
  background: url('/images/logout.png') no-repeat 0 0;
  width: 104px;
  height: 37px;
}

.row_reg {
  position: relative;
  margin-top: 5px;
  padding-left: 18px;
  color: #654109;
  text-decoration: underline;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1.5px;
    width: 14px;
    height: 14px;
    background: url('/images/user-cabinet.svg') no-repeat 0 0;
    background-size: contain;
  }
  &:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 990px) {
  .b_logo {
    padding: 25px 0 205px 40px;
  }

  .b_logo a {
    background: url('/images/logo.png') no-repeat 0 0;
    background-size: 200px auto;
    width: 200px;
    height: 110px;
  }

  .b_auth {
    position: relative;
    padding: 30px 0 16px 13px;
    width: 363px;
    margin: 0 auto 30px;
    background: url('/images/b_auth.png') no-repeat 0 0;
  }
}

@media screen and (max-width: 580px) {
  .b_logo a {
    margin: 0 auto;
  }
}

@media screen and (max-width: 370px) {
  .b_auth {
    padding: 32px 0 16px 10px;
    width: 320px;
    background-size: 320px auto;
    background-position: center;
  }

  .b_auth:before {
    right: 146px;
    top: 30px;
  }

  .row_avatar {
    margin-top: 3px;
  }

  .row_auth {
    margin: 10px 0 0 47px;
  }

  .row_auth_open {
    margin: 0 0 0 10px;
  }

  .btn_brown_round,
  .btn_yellow_round {
    padding: 4px 12px 5px;
  }

  .b_auth .row_auth .title {
    padding-top: 0;
    margin-top: -5px;
  }
}
