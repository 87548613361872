@import '../../../styles/mixins.scss';

.imgWrap {
  position: relative;
  display: inline-block;
  outline: 2px #bbb59a solid;
  outline-offset: -2px;
  border-radius: 3px;
  background-color: rgba(253, 235, 197, 0.45);

  &:before {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 84px;
    height: 102px;
    background: url('/images/book-no-cover.svg') no-repeat 50%;
    background-size: cover;
    z-index: 0;
    opacity: 0.6;
    @include max-screen-480 {
      width: 54px;
      height: 66px;
    }
  }

  &_read {
    width: 100%;
    @include max-screen-580 {
      width: auto;
    }

    &:before {
      width: 108px;
      height: 130px;
      @include max-screen-580 {
        width: 54px;
        height: 66px;
      }
    }

    .img {
      width: 285px;
      @include max-screen-580 {
        width: 160px;
      }
      @include max-screen-480 {
        width: 120px;
      }
    }
  }
}

.img {
  position: relative;
  width: 206px;
  display: block;
  height: auto;
  color: transparent;
  border: 2px #bbb59a solid;
  border-radius: 3px 3px 0px 0px;
  @include max-screen-580 {
    width: 180px;
  }
  @include max-screen-480 {
    width: 120px;
  }
}

.library {
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  &_active {
    .library__list {
      display: block;
    }
  }
  &__btn {
    display: block;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    background-color: #fffae2;
    text-align: center;
    border: 2px solid #bbb59a;
    border-radius: 0 0 3px 3px;
    transition: background-color 0.3s ease;
    text-transform: lowercase;
    @include max-screen-480 {
      text-transform: none;
      padding: 5px;
    }
    &:hover {
      background-color: #fff;
      & .library__btnNote {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__btnAddTxt {
    text-transform: capitalize;
    @include max-screen-480 {
      display: none;
    }
  }
  &__btnNote {
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    background: #9f8968;
    color: white;
    text-align: center;
    border-radius: 3px;
    padding: 3px 5px;
    font-size: 10px;
    white-space: nowrap;
    opacity: 0;
    text-transform: none;
    transition: opacity 0.3s ease;
    visibility: hidden;
    @include max-screen-480 {
      left: 64%;
    }
  }
  &__list {
    position: absolute;
    left: 0;
    top: 38px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    display: none;
    @include max-screen-480 {
      top: 29px;
    }
  }
  &__listItem {
    cursor: pointer;
    display: block;
    padding: 7px 10px;
    position: relative;
    transition: color 0.3s ease;
    font-size: 12px;
    text-align: center;
    @include max-screen-480 {
      padding: 6px 8px;
      font-size: 11px;
    }
    &:hover {
      color: #654109;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: #d9d9d9;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  &__note {
    text-align: center;
    margin-top: 3px;
    font-size: 10px;
    color: #575757;
  }
}
