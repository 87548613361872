@import '../../../styles/mixins.scss';

.footer {
  display: flex;
  flex-direction: column;
  height: 375px;
  font-size: 12px;
  color: #654109;
  @include max-screen-580 {
    height: 400px;
  }

  &_bgshort {
    height: 275px;
    @include max-screen-580 {
      height: 290px;
    }
    .footer__copyrightMenu {
      top: 0;
    }
  }

  &__banner {
    margin-top: auto;
    color: white;
  }

  &__copyright {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 100%;
    justify-content: space-between;
    font-size: 12px;
  }

  &__copyrightMail {
    display: inline-block;
    color: #000;
    margin-bottom: 10px;
    & > a {
      margin-top: 3px;
      display: inline-block;
      text-wrap: nowrap;
      color: #654109;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__copyrighTxt {
    color: #654109;
    font-weight: bold;
    display: block;
  }

  &__copyrightMenu {
    display: flex;
    position: relative;
    top: 3px;
  }

  &__copyrighItem {
    margin-right: 32px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__copyrighLink {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
