.btn_orange {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 7px 15px 8px 15px;
  line-height: normal;
  color: white;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  border: 1px solid #d6774e;
  background: var(--btn-orange-grad, linear-gradient(180deg, #fed262 0%, #d46601 73.5%, #f09223 100%));
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #d46601;
    background: var(--btn-orange-grad-hover, linear-gradient(180deg, #feb951 0%, #d26b0c 73.5%, #e78410 100%));
    opacity: 0;
    transition: opacity 0.3s ease 0s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 480px) {
  .btn_orange_mobWide {
    width: 100%;
    text-align: center;
  }
}
