.outerContainer {
  position: relative;
  &_auth {
    height: 100vh;
    overflow: hidden;
  }
}

.page {
  position: relative;
  background: url('/images/center.jpg') repeat-y center 0 #000;
}

.page_wrapper {
  background: url('/images/footer.jpg') no-repeat center bottom;
}

.page_wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1118px;
  background: url('/images/header.jpg') no-repeat center 0;
}

.page_wrapperBgshort {
  background: url('/images/footer-short.png') no-repeat center bottom;
}

.page_wrapperBgshort:after {
  background: url('/images/header-short.jpg') no-repeat center 0;
  height: 707px;
}

.b_content {
  padding: 0 34px 0 20px;
  margin-left: 194px;
  min-height: auto;
}

.container {
  max-width: 970px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.top_banners {
  width: 100%;
  height: auto;
  padding-bottom: 15px;
}
.top_banner-mobile {
  display: none;
}
.bottom_banners {
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
}
.bottom_banners-book {
  margin: 20px 0;
}
.bottom_banner-mobile {
  display: none;
}

/* adaptive default */
@media screen and (max-width: 990px) {
  .page {
    background-image: url('/images/tablet_bg/center.jpg');
  }

  .page_wrapper:after {
    background-image: url('/images/tablet_bg/header.jpg');
    top: -90px;
  }

  .page_wrapper {
    background-image: url('/images/tablet_bg/footer.jpg');
  }

  .page_wrapperBgshort {
    background: url('/images/tablet_bg/footer-short.png') no-repeat center bottom;
  }

  .page_wrapperBgshort:after {
    background: url('/images/tablet_bg/header-short.jpg') no-repeat center 0;
    height: 707px;
  }

  .b_content {
    margin-left: 0;
    padding: 0 10px;
  }

  .container {
    max-width: 740px;
  }

  .top_banners {
    padding-bottom: 20px;
  }
}
