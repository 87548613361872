.b_left_menu {
  float: left;
  width: 155px;
  padding: 79px 0 0 0;
  position: absolute;
  z-index: 1;
}

.b_left_menu nav {
  padding: 0 0 27px 0;
  font-size: 15px;
}

.b_left_menu ul li {
  padding: 0 0 7px 0;
}

.b_left_menu ul li:before {
  content: '';
  background: url('/images/arraw.gif') no-repeat 0 0;
  position: absolute;
  width: 10px;
  height: 7px;
  margin: 5px 0 0 5px;
}

.single a {
  position: relative;
}

.new a {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: -26px;
    top: -6px;
    width: 27px;
    height: 13px;
    background-image: url('/images/new-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.b_left_menu ul li:hover:before {
  background-image: url('/images/arraw_select.gif');
}

.b_left_menu ul li a {
  color: #f4ecc9;
  padding: 0 0 0 21px;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.b_left_menu ul li a:hover {
  color: #ff9000;
}

.b_left_menu .m_review {
  font-size: 10px;
  color: #939391;
  padding-left: 3px;
}

.b_left_menu .mobile_icon {
  display: none;
  position: absolute;
  top: 0;
  left: 10px;
  width: 40px;
  height: 38px;
  cursor: pointer;
  background: url("data:image/svg+xml,%0A%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='3' rx='1.5' fill='white'/%3E%3Crect y='7' width='20' height='3' rx='1.5' fill='white'/%3E%3Crect y='14' width='20' height='3' rx='1.5' fill='white'/%3E%3C/svg%3E")
    no-repeat 10px 10px;
  transition: all 0.3s ease 0s;
  background-color: rgba(101, 65, 9, 0.8);
  border-radius: 10px;
}

.b_left_menu .mobile_icon:hover {
  opacity: 0.8;
}

.b_left_menu .mobile_icon.act {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.98975' y='0.368164' width='20' height='3' rx='1.5' transform='rotate(45 2.98975 0.368164)' fill='white'/%3E%3Crect x='0.868652' y='14.5103' width='20' height='3' rx='1.5' transform='rotate(-45 0.868652 14.5103)' fill='white'/%3E%3C/svg%3E");
}

.b_left_menu .mobile_wrap {
  padding: 0 0 60px 0;
}

@media screen and (max-width: 990px) {
  .b_left_menu {
    float: none;
    // position: fixed;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    z-index: 999;
  }

  .b_left_menu .mobile_icon {
    display: block;
  }

  .b_left_menu .mobile_wrap {
    display: none;
  }

  .b_left_menu .mobile_wrap.act {
    display: block;
    margin: 0 0 0 50px;
    padding: 20px 8px 0 8px;
    margin-right: 10px;
    box-sizing: border-box;
    background-color: rgba(101, 65, 9, 0.8);
    border-radius: 10px;
  }

  .b_left_menu .mobile_wrap.act nav:last-child {
    margin: 0;
  }
}
