.inputWrap {
  padding: 0 0 20px 0;
}

.input {
  padding: 6px 10px;
  font-size: 14px;
  width: 100%;
  color: #000;
  border-radius: 3px;
  border: 1px solid #ccbc93;
  background: #fdf8e6;
  box-shadow: 0px 1px 3px 0px rgba(55, 17, 17, 0.2) inset;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(55, 17, 17, 0.1) inset;
  }

  &::placeholder {
    color: #a29090;
  }
  //autofill background fix
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.inputLabel {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  display: inline-block;
  &__star {
    color: #ff0000;
  }
}

.inputError {
  font-size: 10px;
  margin-top: 3px;
  color: #ff0000;
}
