.item {
  padding: 0 0 20px 0;
  overflow: auto;
  &:last-child {
    padding: 0;
  }
}

.book_name {
  font-size: 14px;
  font-weight: bold;
  padding: 2px 0 20px 0;
}

.book_name a {
  color: #654109;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.book_card_rating {
  margin-top: 6px;
  text-align: center;
}

.book_card_ratingMob {
  display: none;
}

.book_card_star_rate {
  margin-left: 3px;
  font-size: 12px;
  position: relative;
  top: -2px;
}

.book_card_star {
  width: 13px;
  display: inline-block;
  width: 13px;
  height: 14px;
  margin: 0 2px;
  background-image: url('/images/star-grey.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.book_card_starActive {
  background-image: url('/images/star-orange.svg');
}

/* new_books */
.book_list_desc .annotation .txt {
  text-align: justify;
}

.book_list_desc .img {
  padding: 0 0 15px 0;
  float: left;
}

.book_list_desc .desc {
  overflow: hidden;
  padding-left: 15px;
}

.book_list_desc .book_info {
  padding: 0 0 15px 0;
}

.book_list_desc .book_info .row {
  padding: 0 0 5px 0;
}

.book_list_desc .book_info .row .name {
  color: #654109;
  font-weight: bold;
  padding: 0 0 8px 0;
}

.name_link {
  color: #654109;
  text-decoration: underline;
  display: inline-block;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: none;
  }
}

.book_list_desc .annotation {
  padding: 0 0 15px 0;
}

.book_list_desc .annotation .annotation_title {
  font-size: 12px;
  font-weight: bold;
  color: #654109;
  padding: 0 0 10px 0;
}

.book_list_desc .annotation .txt {
  color: #654109;
  margin-right: 10px;
}

.wrap_btn {
  margin: 0 0 20px 0;
}

.sale_block {
  margin-bottom: 20px;
}

.b_biblio_book_line {
  border-top: 1px dashed #bda76e;
  background: rgba(200, 174, 131, 0.29);
  font: 12px arial;
  color: #7d6435;
  padding: 10px;
  overflow: hidden;
  margin: 0 0 20px 0;
  letter-spacing: -0.2px;
  clear: both;
}

.b_biblio_book_line .bolder_r:after {
  content: '|';
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  margin-top: -1px;
}

.b_biblio_book_line .fb2_info {
  float: right;
}

.comments {
  color: black;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  & a {
    color: #493816;
  }
}

@media screen and (max-width: 767px) {
  .b_biblio_book_line .fb2_info {
    float: none;
  }

  .b_biblio_book_line > span {
    padding: 0;
    &:last-child {
      display: block;
      padding: 8px 0 0 0;
    }
  }

  .b_biblio_book_line .added_user {
    padding: 0 0 3px 0;
  }
}

@media screen and (max-width: 580px) {
  .book_list_desc .desc {
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
  }

  .book_list_desc .book_info {
    overflow: hidden;
    padding-left: 15px;
  }

  .book_list_desc .book_info:after {
    content: '';
    clear: both;
    display: block;
  }

  .book_list_desc .annotation:before {
    content: '';
    clear: both;
    display: block;
  }

  .book_card_rating {
    display: none;
  }

  .book_card_ratingMob {
    display: block;
    text-align: left;
    margin-left: -2px;
  }
  .book_list_desc .annotation .txt {
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .book_card_rating {
    text-align: left;
  }
}
