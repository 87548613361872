.bookCardsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 30px;
}

// .bookCardsList_mb {
//   margin: 0 -10px 0;
// }

// @media screen and (max-width: 378px) {
//   .bookCardsList {
//     justify-content: center;
//   }
// }
