.formBtn {
  display: inline-block;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  border: 1px solid #b59272;
  background: var(--btn-yellow-grad, linear-gradient(180deg, #fef5d5 0%, #f2d673 73%, #ffe896 97.5%));
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;

  &_disabled {
    opacity: 0.7;
    pointer-events: none;
    &:hover {
      .formBtn__txt {
        &::before {
          display: none;
        }
      }
    }
  }
  &__txt {
    padding: 7px 15px 8px 15px;
    color: #744d00;
    font-size: 14px;
    cursor: pointer;
    background: none;
    box-shadow: none;
    border: none;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #f2d673;
    background: var(--btn-yellow-grad, linear-gradient(180deg, #fceebb 0%, #e9cc62 73%, #f7dd80 97.5%));
    opacity: 0;
    transition: opacity 0.3s ease 0s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
